import React ,{ useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BaseUrl, ImgUrl } from "../../common/BaseUrl";
import { Bar, Doughnut } from "react-chartjs-2";
import "react-calendar/dist/Calendar.css";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import Calendar from "./Calendar";
import Performance from "./Performance";
import "./dashboard.css";
import { useNavigate } from 'react-router-dom';
import Notifications from "./Notifications";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const EmployeeDashboard = () => {
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [date, setDate] = React.useState(new Date());
  const [currentTime, setCurrentTime] = useState('');
  const [showColon, setShowColon] = useState(true);
  const navigate = useNavigate();
  const empId = localStorage.getItem("_id");

  const handleClick = () => {
    navigate('/timeTracker'); // Replace with your target URL
  };


  const empDataGetById = async () => {
    try {
      const response = await fetch(`${BaseUrl}/emp/get-by-id/${empId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setData(data.result);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const lastSixMonthTracking = async () => {
    try {
      const response = await fetch(`${BaseUrl}/tracking//monthly-average/${empId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      // Map the response data to chart labels and dataset
      const labels = result.map(entry => entry.month);
      const dataset = result.map(entry => entry.totalElapsedHours);
      setChartData({ labels, dataset });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    if (empId) {
      empDataGetById();
      lastSixMonthTracking();
    }
  }, [empId]);


  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedTime = `${String(hours % 12 || 12).padStart(2, '0')}${showColon ? ':' : ' '}${String(minutes).padStart(2, '0')} ${ampm}`;
      setCurrentTime(formattedTime);
    };

    // Initial time update
    updateTime();

    // Interval to update time every second
    const timeIntervalId = setInterval(updateTime, 1000);

    // Interval to toggle colon every second
    const colonIntervalId = setInterval(() => {
      setShowColon((prev) => !prev);
    }, 1000);

    return () => {
      clearInterval(timeIntervalId);
      clearInterval(colonIntervalId);
    };
  }, [showColon]);

  // Data for Bar Chart
  const barData = {
    labels: chartData.labels || [], // Use fetched labels
    datasets: [
      {
        label: "Hours Spent",
        data: chartData.dataset || [], // Use fetched dataset
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#FF9F40", "#9966FF"],
        borderRadius: 10,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: true, text: "Hours Spent" },
    },
  };



  return (
    <div className="container py-5">
      {/* Header */}
      <h3 className="text-center mb-5 fw-bold" style={{ fontSize: "1.5rem" }}>
        Time & Attendance Leave and Time Off Requests
      </h3>

      {/* Top Row */}
      <div className="row mb-4 gx-4">
        {/* Employee Info */}
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="card p-4 text-center shadow-sm" style={{ borderRadius: "15px" }}>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img
                 src={data?.profile ? `${ImgUrl}/empProfile/${data?.profile}` :  "https://via.placeholder.com/100"}
                  alt="Employee"
                  className="rounded-circle mx-auto mb-3"
                  style={{ width: "100px", height: "100px", objectFit: "cover" }}
                />
              </div>
              <div className="col-md-6 text-left col-sm-12">
                <h6 className="text-muted">Employee In</h6>
                <h3 className="fw-bold mb-1">{currentTime}</h3>
                <button className="btn btn-success px-4 mt-2"  onClick={handleClick}>Clock In/Out</button>
              </div>
            </div>
          </div>
        </div>

        {/* Self-Service Request */}
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="card p-3 shadow-sm" style={{ borderRadius: "15px" }}>
            <h6 className="fw-bold mb-3">Employee Self Service Request</h6>
            <div className="cursor-pointer" onClick={()=>{
              navigate('/leaveManagement');
            }}>
              <p className="d-flex">
                <span className="dashboardIcons mr-2">
                  <i className="bi bi-calendar3 me-2">
                  </i>
                </span> Leave Request</p>
            </div>
            <div className="cursor-pointer" onClick={()=>{
              navigate('/leaveManagement');
            }}>
              <p className="d-flex">
                <span className="dashboardIcons mr-2">
                  <i className="bi bi-clock me-2">
                  </i>
                </span>  Time Off Request</p>
            </div>
          </div>
        </div>

        {/* Calendar */}
        <Calendar />

        {/* Hours Spent */}
        <div className="col-lg-4 mb-4">
          <div className="card p-3 shadow-sm" style={{ borderRadius: "15px", minHeight: "321px" }}>
            <h6 className="fw-bold mb-3">Hours Spent</h6>
            <Bar data={barData} options={barOptions} />
          </div>
        </div>

        {/* Performance */}
        <Performance />

        {/* Notifications */}
        <Notifications/>
      </div>
    </div>
  );
};

export default EmployeeDashboard;
